import type { AppProps } from "next/app";
import getConfig from "next/config";
import Script from "next/script";
import { ErrorDialogProvider } from "../components";
import "../styles/globals.css";
import { register as registerIcons } from "../utils/icons";

registerIcons();

const {
  publicRuntimeConfig: { basePath },
} = getConfig();

function App({ Component, pageProps }: AppProps) {
  if ("noLayout" in Component) {
    return <Component {...pageProps} />;
  }

  return (
    <ErrorDialogProvider>
      <Script src="/recorder.min.js"></Script>
      <Component {...pageProps} />
    </ErrorDialogProvider>
  );
}

export default App;
